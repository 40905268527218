import { defineStore } from "pinia";
import { TokenState } from "./auth.dto";

export const useAuthToken = defineStore({
  id: "auth-accesstoken",
  state: (): TokenState => {
    return {
      accessToken: "",
      refreshToken: "",
      refreshingToken: false,
    };
  },
  getters: {
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
    IsRefreshing: (state) => state.refreshingToken,
    hasToken: (state) => state.accessToken && state.refreshToken,
  },
  actions: {
    initToken() {
      this.accessToken = localStorage.getItem("accessToken");
      this.refreshToken = localStorage.getItem("refreshToken");
    },
    setToken(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    },
    setRefreshingToken(refreshingToken: boolean) {
      this.refreshingToken = refreshingToken;
    },
    clearToken() {
      this.accessToken = "";
      this.refreshToken = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
  },
});

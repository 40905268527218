import { useLastPage } from "@/store/auth/lastPage";
import { useAuthToken } from "@/store/auth/token";
import { useUserInfo } from "@/store/user/userInfo";
import axios from "axios";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "model",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/callback:params(.*)",
    name: "callback",
    component: () => import("../views/Auth/Callback.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/model",
    component: () => import("../views/Base.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "modelList",
        component: () => import("../views/model/ModelListPage.vue"),
        meta: { isGuest: false },
      },
      {
        path: ":modelHashKey",
        name: "modelDetail",
        component: () => import("../views/model/ModelDetailPage.vue"),
      },
      {
        path: ":modelHashKey/transaction/create",
        name: "modelCreateTransaction",
        component: () => import("../views/transaction/CreateTransaction.vue"),
      },
      {
        path: "create",
        name: "modelCreate",
        component: () => import("../views/model/CreateModel.vue"),
      },
      {
        path: "edit/:modelHashKey",
        name: "modelEdit",
        component: () => import("../views/model/CreateModel.vue"),
      },
    ],
  },
  {
    path: "/transaction/:transactionHashKey",
    name: "transactionDetail",
    component: () => import("../views/transaction/TransactionDetail.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userInfo = useUserInfo();
  const authToken = useAuthToken();
  const lastPage = useLastPage();
  userInfo.initUser();
  authToken.initToken();

  // get couponToken from url
  const couponToken = to.query.coupon;
  if (couponToken) {
    const returnTokens = (
      await axios.get(`/auth/callback/coupon/${couponToken}`)
    ).data;
    authToken.setToken(returnTokens.accessToken, returnTokens.refreshToken);

    // get user info
    const user = await axios.get(`user`);

    // set user info
    userInfo.setUserInfo(user.data);
  }

  // Before go to login, if interceptor has send url with params, then go to login page and set localStorage.
  if (to.name === "login" && to.params.protectedRoute) {
    lastPage.setLastPage(from.fullPath);
    next();
  }

  // if user has no userInfo or accessToken and refreshToken, then register user as a guest
  if (!userInfo.hasInfo && to.meta.requiresAuth) {
    if (to.meta.isGuest === false) {
      router.push({
        name: "login",
      });
    } else {
      // then register this user as a guest user
      const returnTokens = await axios.get(`/auth/callback/guest`);

      authToken.setToken(
        returnTokens.data.accessToken,
        returnTokens.data.refreshToken
      );

      // get user info
      const user = await axios.get(`user`);

      // set user info
      userInfo.setUserInfo(user.data);
      next();
    }
  } else {
    next();
  }
});

export default router;

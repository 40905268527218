import axios from "axios";
import { useAuthToken } from "@/store/auth/token";
import router from "@/router";
import { useSnackBar } from "@/store/alert/snackbar";

export const axiosSetup = () => {
  const snackBar = useSnackBar();
  // Setup Axios BASE API URL
  const { VUE_APP_API_URL } = process.env;
  axios.defaults.baseURL = VUE_APP_API_URL;

  axios.interceptors.request.use(
    (config) => {
      const authToken = useAuthToken();
      authToken.initToken();
      const accessToken = authToken.getAccessToken;
      console.log("test access: ", accessToken);

      config.headers = {};
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.contentType = `application/json`;

      return config;
    },
    (err) => {
      return Promise.reject(err);
    },
    { synchronous: true }
  );

  axios.interceptors.response.use(undefined, async (error: any) => {
    // token expired
    const originalConfig = error.config;
    // console.log(`URL: ${error.config.baseURL}/${error.config.url}`);
    if (originalConfig.url !== "auth/login" && error.response) {
      const authToken = useAuthToken();
      if (
        error.response.status == 401 &&
        !originalConfig._retry &&
        !authToken.IsRefreshing
      ) {
        originalConfig._retry = true;
        authToken.initToken();
        try {
          authToken.setRefreshingToken(true);

          // Get new accessToken from refreshToken
          const refreshToken = await axios.post(`auth/refresh`, {
            refreshToken: authToken.getRefreshToken,
          });

          // store new accessToken and refreshToken
          authToken.setToken(
            refreshToken.data.accessToken,
            refreshToken.data.refreshToken
          );

          authToken.setRefreshingToken(false);

          // retry the request with the new accessToken
          return axios(originalConfig);
        } catch (_error) {
          // clear token
          authToken.clearToken();
          snackBar.setSnackBarData({
            showIcon: true,
            alertType: "warning",
            msg: "กรุณาล็อคอินใหม่แล้วลองอีกครั้ง",
          });
          snackBar.showSnackBar();

          // if refreshToken is expired, redirect to login page
          router.push({
            name: "login",
            params: {
              protectedRoute: "true",
            },
          });

          return Promise.reject(_error);
        }
      } else if (error.response.status == 403 && !originalConfig._retry) {
        originalConfig._retry = true;
        // alert permission denied
        snackBar.setSnackBarData({
          showIcon: true,
          alertType: "warning",
          msg: "Permission denied",
        });
        snackBar.showSnackBar();

        // clear token
        authToken.clearToken();

        // Permission denied, Go to login page
        return router.push({
          name: "login",
          params: {
            protectedRoute: "true",
          },
        });
      } else if (error.response.status == 404 && !originalConfig._retry) {
        originalConfig._retry = true;
        // Page not found, Go to home page
        return router.push({
          name: "home",
        });
      }
    }
    return Promise.reject(error);
  });
};

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import { createPinia } from "pinia";
import { axiosSetup } from "@/helpers/interceptor";

const pinia = createPinia();

const app = createApp(App).use(store).use(router).use(pinia);

axiosSetup();
app.mount("#app");
import { defineStore } from "pinia";
import { LastPageState } from "./auth.dto";

export const useLastPage = defineStore({
  id: "auth-lastpage",
  state: (): LastPageState => {
    return {
      lastPage: "",
    };
  },
  getters: {
    getLastPage: (state) => state.lastPage,
  },
  actions: {
    initLastPage() {
      this.lastPage = localStorage.getItem("lastPage");
    },
    setLastPage(lastPage: string) {
      this.lastPage = lastPage;
      localStorage.setItem("lastPage", lastPage);
    },
    clearLastPage() {
      this.lastPage = "";
      localStorage.removeItem("lastPage");
    },
  },
});

import { defineStore } from "pinia";
type SnackBarState = {
  alertType: 'success' | 'warning' | 'error'
  msg: string
  showIcon: boolean
  isShow?: boolean
  timeout: number
}
type SnackBarProps = {
  alertType: 'success' | 'warning' | 'error'
  msg: string
  showIcon: boolean
  isShow?: boolean
  timeout?: number
}
type State = {
  snackBarData: SnackBarState
  timeoutEvent: undefined | NodeJS.Timeout
}

export const useSnackBar = defineStore({
  id: "alert-snackbar",
  state: (): State => {
    return {
      snackBarData: {
        alertType: 'error',
        msg: '',
        showIcon: false,
        isShow: false,
        timeout: 2000
      },
      timeoutEvent: undefined
    };
  },
  getters: {
    getSnackBarData: (state) => state.snackBarData,
  },
  actions: {
    setSnackBarData (snackBarData:SnackBarProps) {
      this.snackBarData = {
        ...this.snackBarData,
        ...snackBarData
      }
    },
    showSnackBar () {
      // clear old timeout
      if(this.timeoutEvent != undefined){
        clearTimeout(this.timeoutEvent)
      }
      this.snackBarData.isShow = true
      // timeout delay before hide (config + 500ms for transition fade out)
      this.timeoutEvent = setTimeout(() => {
        this.snackBarData.isShow = false
        this.timeoutEvent = undefined
      }, this.snackBarData.timeout + 500)
    }
  },
});

import { defineStore } from "pinia";
import { UserState } from "./user.dto";

export const useUserInfo = defineStore({
  id: "user-info",
  state: (): UserState => {
    return {
      id: "",
      email: "",
      socialId: "",
      displayName: "",
      picUrl: "",
      isStaff: "",
      provider: "",
    };
  },
  getters: {
    getId: (state) => state.id,
    getEmail: (state) => state.email,
    getSocialId: (state) => state.socialId,
    getDisplayName: (state) => state.displayName,
    getPicUrl: (state) => state.picUrl,
    getIsStaff: (state) => state.isStaff,
    getProvider: (state) => state.provider,
    hasInfo: (state) => state.id && state.email && state.provider !== "guest",
  },
  actions: {
    initUser() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
      (Object.keys(userInfo) as Array<keyof UserState>).map((key) => {
        this[key] = userInfo[key];
      });
    },
    setUserInfo(userStateDto: UserState) {
      (Object.keys(userStateDto) as Array<keyof UserState>).map((key) => {
        this[key] = userStateDto[key];
      });
      localStorage.setItem("userInfo", JSON.stringify(userStateDto));
    },
    clearUserInfo() {
      this.id = null;
      this.email = "";
      this.socialId = "";
      this.displayName = "";
      this.picUrl = "";
      this.isStaff = null;
      this.provider = "";
      localStorage.removeItem("userInfo");
    },
  },
});
